/**
 * Interface for authenticating with server /auth endpoint.
 */
import axios from "axios";
import { getServerLink } from "globals/utils/getServerLink";

const getAuthEndpoint = () => `${getServerLink()}/auth/customer`;

axios.defaults.withCredentials = true;

type sendCodeProps = {
  mobilePhone: {
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  email: string;
  operatorSlug: string;
};
// send-code
const sendCode = async (props: sendCodeProps) => {
  const { mobilePhone, operatorSlug, email } = props;

  try {
    const res = await axios.post(
      `${getAuthEndpoint()}/send-code`,
      {
        operatorSlug,
        email,
        mobilePhone,
      }
    );

    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

type loginProps = {
  mobilePhone: {
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  email: string;
  code: string;
  operatorSlug: string;
};
// login
const login = async (props: loginProps) => {
  const { mobilePhone, email, code, operatorSlug } = props;

  try {
    const res = await axios.post(
      `${getAuthEndpoint()}/login`,
      {
        code,
        operatorSlug,
        mobilePhone,
        email,
      }
    );

    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

type loginSSOProps = {
  idToken?: string;
  operatorSlug: string;
  provider: string;
};
// login SSO
const loginSSO = async (props: loginSSOProps) => {
  const { operatorSlug, idToken, provider } = props;

  try {
    const res = await axios.post(
      `${getAuthEndpoint()}/${provider}/login`,
      {
        operatorSlug,
        idToken,
      }
    );

    return {
      data: res.data,
      status: res.status,
    };
  } catch (error) {
    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

type logoutProps = {
  operatorId: string;
};
// logout
const logout = async (props: logoutProps) => {
  const { operatorId } = props;

  try {
    await axios.post(`${getAuthEndpoint()}/logout`, {
      operatorId,
    });
  } catch (error) {
    console.error(error);
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sendCode,
  login,
  loginSSO,
  logout,
};
