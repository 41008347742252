/**
 * @file Router.tsx
 * Main router for site. Right now just switches between operator app and driver app.
 *
 * reactComponents:
 * Router
 */

import React, { lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch } from "react-router-dom";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import useTheme from "useTheme";

import { ThemeProvider } from "@mui/material/styles";

import MoovsApolloProvider from "./providers/MoovsApolloProvider/MoovsApolloProvider";
import {
  useForceReload,
  useLaunchDarklyFlags,
  useIdentifyLDClient,
  useOperator,
} from "globals/hooks";
import { AuthProvider } from "globals/hooks/useAuth/Provider";
import StripeElementsProvider from "providers/StripeElementsProvider";
import PrivateRoute from "utils/auth/PrivateRoute";
import { formatPhoneNumber } from "utils/phoneNumberFormatter/phoneNumberFormatter";
import Layout from "components/Layout";
import FourZeroFourPage from "pages/404Page";
import AllTripsPage from "pages/allTrips/AllTripsPage/AllTripsPage";
import InvoicePage from "pages/invoice/InvoicePage";
import InvoicePayPage from "pages/invoice/InvoicePayPage";
import OrdersPage from "pages/orders/OrdersPage";
import PriceSummaryPage from "pages/price-summary/PriceSummaryPage";
import PriceSummaryPayPage from "pages/price-summary/PriceSummaryPayPage";
import ProfilePage from "pages/profile/ProfilePage";
import TermsAndConditionsPage from "pages/terms-and-conditions/TermsAndConditionsPage";
import TripPage from "pages/trip/TripPage";
import CreateRequestPage from "pages/new/CreateRequestPage";
import OrderPage from "pages/order/OrderPage";
import LoadingPage from "pages/LoadingPage";
import IframeRequestPage from "pages/iframe/IframeRequestPage";
import DeactivatedPage from "pages/DeactivatedPage";
import TripReviewPage from "pages/trip-review/TripReviewPage";
import PassengersPage from "pages/passengers/PassengersPage";

// lazy loaded pages (for code-splitting)
const Sandbox = lazy(() => import("pages/Sandbox"));
const WhiteLabelPage = lazy(() => import("pages/booking/WhiteLabelPage"));
const DudaIframeRequestPage = lazy(
  () => import("pages/iframe/DudaIframeRequestPage")
);

const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
  uuid: null,
});

function Router() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <MoovsApolloProvider>
        <Switch>
          <Route path="/404" children={<FourZeroFourPage />} />
          <Route path="/iframe" children={<DudaIframeRequestPage />} />
          <Route
            path="/:operatorSlug/iframe"
            children={<IframeRequestPage />}
          />
          <Route path="/:operatorSlug/deactivated">
            <DeactivatedPage />
          </Route>
          <StripeElementsProvider>
            <Route path="*" children={<CustomerRouter />} />
          </StripeElementsProvider>
        </Switch>
      </MoovsApolloProvider>
    </Suspense>
  );
}

function CustomerRouter() {
  // hooks
  const {
    operator: { name, voicePhoneNumber, companyLogoUrl, id, twilioPhoneNumber },
  } = useOperator();
  const theme = useTheme();
  const { enableLinkedPassenger } = useLaunchDarklyFlags();
  useIdentifyLDClient();

  // force reload on iOS to handle pin. Here so it doesn't force reload on iFrames widgets
  useForceReload();

  const twilioNumber = formatPhoneNumber(
    twilioPhoneNumber?.phoneNumber
  )?.formatted;
  pubnub.setUUID(id);

  return (
    <ThemeProvider theme={theme}>
      <PubNubProvider client={pubnub}>
        <Layout>
          <Switch>
            {/* white label is here so it has theme, but not inside auth provider b/c it doesn't work with authenticated users */}
            <Route
              path="/:operatorSlug/booking"
              children={<WhiteLabelPage />}
            />

            <AuthProvider>
              <Helmet>
                <meta property="og:title" content={name} />
                <meta
                  property="og:description"
                  content={`Have questions? Call ${
                    twilioNumber || voicePhoneNumber
                  }`}
                />
                <meta property="og:image" content={companyLogoUrl} />
              </Helmet>

              <Switch>
                {/* developer sandbox */}
                {isDev && (
                  <Route path="/:operatorSlug/sandbox" children={<Sandbox />} />
                )}

                {/* redirects */}
                <Route
                  exact
                  path="/:operatorSlug"
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: `/${props.match.params.operatorSlug}/new/info`,
                        search: props.location.search,
                      }}
                    />
                  )}
                />
                {/* The below redirects are to handle deprecated routes */}
                <Route
                  path="/:operatorSlug/request/new"
                  render={(props) => (
                    <Redirect
                      to={{
                        pathname: `/${props.match.params.operatorSlug}/new/info`,
                        search: props.location.search,
                      }}
                    />
                  )}
                />
                <Route
                  exact
                  path="/:operatorSlug/request/:requestId/price-summary"
                  render={(props) => (
                    <Redirect
                      to={`/${props.match.params.operatorSlug}/price-summary/${props.match.params.requestId}`}
                    />
                  )}
                />
                <Route
                  path="/:operatorSlug/request/:requestId"
                  render={(props) => (
                    <Redirect
                      to={`/${props.match.params.operatorSlug}/order/${props.match.params.requestId}`}
                    />
                  )}
                />

                {/* routes */}
                {/* new request routes */}
                <Route
                  // page is "info" "vehicle" or "confirm"
                  path="/:operatorSlug/new"
                  children={<CreateRequestPage />}
                />

                {/* all trips page */}
                <Route
                  path="/:operatorSlug/dispatch/:requestId/"
                  children={<AllTripsPage />}
                />

                {/* existing order routes */}
                <Route
                  path="/:operatorSlug/order/:requestId"
                  children={<OrderPage />}
                />

                {/* price summary pay page */}
                <Route
                  path="/:operatorSlug/price-summary/:requestId/pay"
                  exact
                  children={<PriceSummaryPayPage />}
                />
                {/* price summary page */}
                <Route
                  path="/:operatorSlug/price-summary/:requestId/"
                  children={<PriceSummaryPage />}
                />

                {/* public trip page */}
                {/* TODO: Change this to route page? It is at route not trip level */}
                <Route
                  path="/:operatorSlug/trip/:publicId"
                  exact
                  children={<TripPage />}
                />

                {/* invoice pay page */}
                <Route
                  path="/:operatorSlug/invoice/:invoiceId/pay"
                  exact
                  children={<InvoicePayPage />}
                />

                {/* invoice page */}
                <Route
                  path="/:operatorSlug/invoice/:invoiceId"
                  exact
                  children={<InvoicePage />}
                />

                {/* terms and conditions page */}
                <Route
                  path="/:operatorSlug/terms-and-conditions"
                  exact
                  children={<TermsAndConditionsPage />}
                />

                {/* trip review page */}
                <Route
                  path="/:operatorSlug/trip-review/:tripId"
                  exact
                  children={<TripReviewPage />}
                />

                {/* user profile page */}
                <PrivateRoute
                  path="/:operatorSlug/user/profile"
                  children={<ProfilePage />}
                />

                {/* user orders page */}
                <PrivateRoute
                  path="/:operatorSlug/user/orders"
                  children={<OrdersPage />}
                />

                {/* user passengers page */}
                {enableLinkedPassenger && (
                  <PrivateRoute
                    path="/:operatorSlug/user/passengers"
                    children={<PassengersPage />}
                  />
                )}

                {/* 404 */}
                <Route path="*" children={<FourZeroFourPage />} />
              </Switch>
            </AuthProvider>
          </Switch>
        </Layout>
      </PubNubProvider>
    </ThemeProvider>
  );
}

export default Router;
