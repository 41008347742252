import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";

import { Box, Typography, useTheme } from "@mui/material";

import { greenDark, orange, tintOrange } from "../../../design-system/colors";
import { EditPenIcon, InfoIcon } from "../../../design-system/icons";
import CircleGreenCheckIcon from "design-system/icons/CircleGreenCheckIcon";
import { useScreenSize, usePrevious } from "../../../globals/hooks";
import MoovsBanner, { MoovsBannerProps } from "../../globals/MoovsBanner";
import MoovsDialog from "../../globals/MoovsDialog";
import mailCheck from "../../../illustrations/mailCheckBlue.svg";
import { SubmitSuccessLottie } from "design-system/lottie";
import RequestChangesToTripDialog from "../RequestChangesToTripDialog/RequestChangesToTripDialog";
import { Trip } from "types";

type SolicitChangeBannerProps = {
  trip: Trip;
};

function SolicitChangeBanner(props: SolicitChangeBannerProps) {
  const { trip } = props;
  const { canAutomateCustomerChange } = trip;

  // hooks
  const { isMobileView } = useScreenSize();
  const prevTripId = usePrevious(trip.id);
  const theme = useTheme();

  // state
  const [isSolicitUpdateDialogOpen, setIsSolicitUpdateDialogOpen] =
    useState<boolean>(false);
  const [
    isSolicitUpdateEmailSentDialogOpen,
    setIsSolicitUpdateEmailSentDialogOpen,
  ] = useState<boolean>(false);
  const [hasSolicitedTripChange, setHasSolicitedTripChange] =
    useState<boolean>(false);

  // event handlers
  const handleBannerClick = () => {
    setHasSolicitedTripChange(true);
  };

  const handleSuccessfulSolicit = () => {
    handleBannerClick();
    setIsSolicitUpdateDialogOpen(false);
    setIsSolicitUpdateEmailSentDialogOpen(true);
  };

  const handleCloseUpdateTripDialog = () => {
    setIsSolicitUpdateDialogOpen(false);
  };

  // effects
  // reset trip shape on dialog close
  useEffect(() => {
    if (prevTripId !== trip.id) {
      setHasSolicitedTripChange(false);
    }
  }, [prevTripId, trip.id]);

  let solicitBannerProps: MoovsBannerProps;
  if (hasSolicitedTripChange) {
    solicitBannerProps = {
      bannerText: canAutomateCustomerChange
        ? "All changes have been successfully updated!"
        : "Trip changes have been requested and are pending approval.",
      bannerBgColor: canAutomateCustomerChange ? "#EBF5EF" : tintOrange,
      bannerIcon: canAutomateCustomerChange ? (
        <CircleGreenCheckIcon />
      ) : (
        <InfoIcon color={orange} />
      ),
      ...(canAutomateCustomerChange && { bannerTextColor: greenDark }),
    };
  } else {
    solicitBannerProps = {
      bannerText: "Need to make an update to your trip?",
      bannerBgColor: theme.palette.primary.light,
      actionButtonText: `${
        canAutomateCustomerChange ? "Make" : "Request"
      } Changes`,
      onActionButtonClick: () => setIsSolicitUpdateDialogOpen(true),
      actionButtonStartIcon: <EditPenIcon />,
      actionButtonVariant: "outlined",
    };
  }

  return (
    <>
      <MoovsBanner
        {...solicitBannerProps}
        {...(isMobileView && { flexDirection: "column" })}
      />
      <RequestChangesToTripDialog
        open={isSolicitUpdateDialogOpen}
        onSuccessfulSolicit={handleSuccessfulSolicit}
        onClose={handleCloseUpdateTripDialog}
        trip={trip}
      />
      <MoovsDialog
        open={isSolicitUpdateEmailSentDialogOpen}
        dialogTitle={`${
          canAutomateCustomerChange ? "Make" : "Request"
        } Changes to Trip`}
        size="sm"
        onClose={() => setIsSolicitUpdateEmailSentDialogOpen(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          py={3}
          {...(isMobileView && { height: "100%", justifyContent: "center" })}
        >
          {canAutomateCustomerChange ? (
            <Lottie
              animationData={SubmitSuccessLottie}
              play
              loop={false}
              segments={[0, 112]}
              style={{
                height: "150px",
              }}
            />
          ) : (
            <img src={mailCheck} alt="Swoop" style={{ height: "98px" }} />
          )}
          <Box pt={3} pb={1}>
            <Typography variant="h3">
              {canAutomateCustomerChange
                ? "Changes confirmed!"
                : "Your request has been sent."}
            </Typography>
          </Box>
          <Box pb={2}>
            <Typography variant="body2" color="textSecondary">
              {canAutomateCustomerChange
                ? "Your trip updates have successfully been made."
                : "You will hear back if your changes are approved."}
            </Typography>
          </Box>
        </Box>
      </MoovsDialog>
    </>
  );
}

export default SolicitChangeBanner;
