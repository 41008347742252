import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import MoovsDialog from "../../globals/MoovsDialog";
import { SOLICIT_CHANGES_TO_TRIP, LOAD_REQUEST_QUERY } from "globals/graphql";
import { useAnalytics, useSnackbar } from "globals/hooks";
import {
  UpdatedTripReducerActionTypesEnum,
  useUpdatedTripReducer,
} from "../hooks/useUpdatedTripReducer";
import { useSolicitChangesForTripStopsErrors } from "../hooks/useSolicitChangesForTripStopsErrors";
import { Trip } from "types";
import { getErrorMessage } from "moovsErrors/getErrorMessage";
import StopsBlock from "pages/new/steps/info/components/StopsBlock/StopsBlock";

type RequestChangesToTripDialogProps = {
  open: boolean;
  onClose: () => void;
  onSuccessfulSolicit: () => void;
  trip: Trip;
};

function RequestChangesToTripDialog(props: RequestChangesToTripDialogProps) {
  const { open, onClose, onSuccessfulSolicit, trip } = props;
  const { canAutomateCustomerChange } = trip;

  const { STOPS, INITIAL_STATE } = UpdatedTripReducerActionTypesEnum;

  // state
  const [requestErrors, setRequestErrors] = useState({
    stops: [
      {
        location: "",
        dateTime: "",
        stopIndex: 1,
      },
      {
        location: "",
        dateTime: "",
        stopIndex: 2,
      },
    ],
    totalGroupSize: "",
    orderType: "",

    // contact
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    mobilePhone: "",
  });
  const [isFetchingFlightInfo, setIsFetchingFlightInfo] = useState(false);

  // Reducer
  const [updatedTrip, dispatch] = useUpdatedTripReducer(trip);

  // effects
  // when props.trip changes, we want the trip state of RequestChangesToTripDialog to be updated as well.
  // this effect syncs trip state with props.trip
  useEffect(() => {
    dispatch({
      type: INITIAL_STATE,
      payload: trip,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, INITIAL_STATE, trip.id]);

  // hooks
  const { requestId } = useParams<{ requestId: string }>();
  const snackbar = useSnackbar();
  const { track } = useAnalytics();
  const {
    tripStopsError,
    validateSolicitChangesForTripStops,
    setErrorToInitialState,
  } = useSolicitChangesForTripStopsErrors();

  // mutations
  const [solicitChangesToTrip, { loading: isSubmittingChanges }] = useMutation(
    SOLICIT_CHANGES_TO_TRIP,
    {
      refetchQueries: [
        { query: LOAD_REQUEST_QUERY, variables: { id: requestId } },
      ],
      onCompleted() {
        track("changes_requested", {
          process: canAutomateCustomerChange ? "automated" : "requested",
        });
        onSuccessfulSolicit();
        handleCloseRequestChangesToTripDialog();
      },
      onError(error) {
        const errorMessage =
          getErrorMessage(error) ||
          `Error ${
            canAutomateCustomerChange ? "making" : "requesting"
          } changes on trip.`;

        snackbar.error(errorMessage);
      },
    }
  );

  // handlers
  const handleStopDetailsChange = (value?: any) => {
    dispatch({
      type: STOPS,
      value,
    });
    setErrorToInitialState();
  };

  const handleSolicitTripChanges = () => {
    const hasErrors = validateSolicitChangesForTripStops(
      trip.stops,
      updatedTrip.stops
    );

    if (hasErrors) return;

    solicitChangesToTrip({
      variables: {
        input: {
          tripId: trip.id,
          stops: updatedTrip.stops.map((stop) => ({
            stopId: stop.id,
            stopIndex: stop.stopIndex,
            location: stop.airport ? stop.airport.airportName : stop.location,
            dateTime: stop.dateTime,
            flightNumber: stop.flightNumber,
            airportIcao: stop.airport?.icaoCode,
            airlineIcao: stop.airline?.icaoCode,
            ...(stop.trackedFlight && { trackedFlight: stop.trackedFlight }),
          })),
        },
      },
    });
  };

  const handleCloseRequestChangesToTripDialog = () => {
    setErrorToInitialState();
    onClose();
    dispatch({
      type: INITIAL_STATE,
      payload: trip,
    });
  };

  return (
    <MoovsDialog
      hideLoadingIndicator={false}
      acceptDisabled={isFetchingFlightInfo || isSubmittingChanges}
      open={open}
      onClose={handleCloseRequestChangesToTripDialog}
      dialogTitle={`${
        canAutomateCustomerChange ? "Make" : "Request"
      } Changes to Trip`}
      size="sm"
      fixedFooter
      removeCloseButton={true}
      onAccept={handleSolicitTripChanges}
      acceptButtonText={`${
        canAutomateCustomerChange ? "Make" : "Request"
      } Changes`}
      errorText={tripStopsError}
    >
      <StopsBlock
        trip={updatedTrip}
        onTripChange={(newTrip) => handleStopDetailsChange(newTrip.stops)}
        requestErrors={requestErrors}
        setRequestErrors={setRequestErrors}
        // prevent changing last dateTime when hourly mode is selected.
        disableEditingLastDateTime={true}
        canOnlyUpdateStopDate
        setIsFetchingFlightInfo={setIsFetchingFlightInfo}
      />
    </MoovsDialog>
  );
}

export default RequestChangesToTripDialog;
