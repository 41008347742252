import React from "react";
import first from "lodash/first";

import { Box, Divider } from "@mui/material";

import {
  CreateRequestState,
  CreateTripState,
} from "pages/new/context/initialState";
import { useScreenSize } from "globals/hooks";
import TripItineraryCard from "./components/TripItineraryCard/TripItineraryCard";
import { RoundTripVariant } from "types";
import VehicleCard from "./components/VehicleCard";
import { useIsWhiteLabel } from "pages/new/hooks";
import AdditionalTripInfoBlock from "./components/TripItineraryCard/components/AdditionalTripInfoBlock/AdditionalTripInfoBlock";

type TripDetailsCreateBlockProps = {
  trip: CreateTripState;
  returnTrip: CreateTripState;
  bookingContact: CreateRequestState["bookingContact"];
};

function TripDetailsCreateBlock(props: TripDetailsCreateBlockProps) {
  const { trip, returnTrip, bookingContact } = props;

  // hooks
  const { isTabletView, isMobileView } = useScreenSize();
  const { isWhiteLabel } = useIsWhiteLabel();

  // derived state
  const vehicle = first(trip.routes).vehicle;

  return (
    <>
      <Box
        display="flex"
        flex="3"
        flexDirection="column"
        {...(!isTabletView && { mr: 2 })}
      >
        <Box mb={2}>
          <TripItineraryCard
            isQuote={false}
            additionalTripInfoBlock={
              <AdditionalTripInfoBlock
                mode="create"
                trip={trip}
                passenger={bookingContact}
              />
            }
            trip={{
              ...trip,
              ...(returnTrip && {
                roundTripVariant: RoundTripVariant.Outbound,
              }),
            }}
          />
          <Box my={2}>
            <VehicleCard vehicle={vehicle} isWhiteLabel={isWhiteLabel} />
          </Box>

          {/* return trip */}
          {returnTrip && (
            <>
              <Divider />
              <Box
                display="flex"
                flexDirection={isMobileView ? "column" : "row"}
                pt={3}
              />
              <TripItineraryCard
                additionalTripInfoBlock={
                  <AdditionalTripInfoBlock
                    mode="create"
                    trip={returnTrip}
                    passenger={bookingContact}
                  />
                }
                trip={{
                  ...returnTrip,
                  roundTripVariant: RoundTripVariant.Return,
                }}
              />
              <Box my={2}>
                <VehicleCard vehicle={vehicle} isWhiteLabel={isWhiteLabel} />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
}

export default TripDetailsCreateBlock;
