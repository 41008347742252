import React from "react";

import { Box, Divider } from "@mui/material";

import DurationDisplay from "./DurationDisplay";
import PassengerCountDisplay from "./PassengerCountDisplay";
import HeaderConfirmationNumber from "./HeaderConfirmationNumber";
import { useScreenSize } from "../../../../../../globals/hooks";
import { RoundTripVariant, TripCategory } from "../../../../../../types";
import TripTypeLabel from "./TripTypeLabel";

type TripItineraryHeaderProps = {
  totalDuration: number;
  estimatedDuration: number;
  useTotalDuration: boolean;
  totalGroupSize: number;
  confirmationNumber?: string;
  tripCategory?: TripCategory;
  roundTripVariant?: RoundTripVariant;
};

function TripItineraryHeader(props: TripItineraryHeaderProps) {
  const {
    totalDuration,
    estimatedDuration,
    useTotalDuration,
    totalGroupSize,
    confirmationNumber,
    tripCategory,
    roundTripVariant,
  } = props;

  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flexDirection={isMobileView ? "column" : "row"}
      justifyContent="space-between"
    >
      <TripTypeLabel
        tripCategory={tripCategory}
        roundTripVariant={roundTripVariant}
      />
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        {...(isMobileView && { mt: "18px" })}
      >
        <HeaderConfirmationNumber confirmationNumber={confirmationNumber} />

        {/* duration */}
        <DurationDisplay
          totalDuration={totalDuration}
          estimatedDuration={estimatedDuration}
          isEstimated={!useTotalDuration}
        />

        <Divider
          flexItem
          orientation="vertical"
          style={{ marginLeft: "16px", marginRight: "16px" }}
        />

        {/* passengers */}
        <PassengerCountDisplay passengerCount={totalGroupSize} />
      </Box>
    </Box>
  );
}

export default TripItineraryHeader;
