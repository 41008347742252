import React from "react";

import { Box, Button, Typography } from "@mui/material";

import { useScreenSize } from "../../globals/hooks";

export type MoovsBannerProps = {
  bannerText: string;
  bannerBgColor: string;
  bannerTextColor?: string;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  actionButtonStartIcon?: JSX.Element;
  actionButtonVariant?: "outlined";
  bannerIcon?: JSX.Element;
};

function MoovsBanner(props: MoovsBannerProps) {
  const {
    bannerText,
    actionButtonText,
    onActionButtonClick,
    bannerBgColor,
    bannerTextColor,
    actionButtonStartIcon,
    actionButtonVariant,
    bannerIcon,
  } = props;

  // hooks
  const { isMobileView } = useScreenSize();

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection={isMobileView ? "column" : "row"}
      px={3}
      py={2}
      borderRadius="4px"
      bgcolor={bannerBgColor}
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {!!bannerIcon && <Box mr={2}>{bannerIcon}</Box>}
        <Typography
          variant="subtitle1"
          {...(!!bannerTextColor && { style: { color: bannerTextColor } })}
        >
          {bannerText}
        </Typography>
      </Box>
      {onActionButtonClick && (
        <Button
          color="primary"
          variant={actionButtonVariant || "contained"}
          onClick={onActionButtonClick}
          {...(!!actionButtonStartIcon && {
            startIcon: actionButtonStartIcon,
          })}
          {...(isMobileView && { style: { marginTop: "8px" } })}
        >
          {actionButtonText}
        </Button>
      )}
    </Box>
  );
}

export default MoovsBanner;
