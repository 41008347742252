import React, { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import authApi from "../../../../utils/auth/authApi";
import {
  useOperatorSlug,
  useAuth,
} from "../../../../globals/hooks";
import { white } from "../../../../design-system/colors";
import { formatPhoneNumber } from "../../../../utils/phoneNumberFormatter/phoneNumberFormatter";
import { ErrorMessages } from "moovsErrors/errorCodesMap";

type VerifyCodeBlockProps = {
  mobilePhone: {
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  email: string;
  verifyButtonText?: string;
  verifyCodeText?: string;
  verifyButtonIcon?: any;
  onVerifySuccess?: (() => Promise<void>) | (() => void);
};

function VerifyCodeBlock(props: VerifyCodeBlockProps) {
  const {
    email,
    mobilePhone,
    onVerifySuccess,
    verifyCodeText,
    verifyButtonText,
    verifyButtonIcon,
  } = props;

  const operatorSlug = useOperatorSlug();

  const { onLogin } = useAuth();

  // state
  const [verifyCode, setVerifyCode] = useState("");
  const [verifyCodeErrors, setVerifyCodeErrors] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);

  // event handlers
  const handleVerifyCodeChange = (e: any) => {
    setVerifyCode(e.target.value);
  };

  const handleVerifyCodeSubmit = async (e: any) => {
    e.preventDefault();

    if (verifyCode.length < 6) {
      setVerifyCodeErrors("Verify code requires 6 digit");
      return;
    }

    setVerifyCodeErrors("");
    setIsSendingCode(true);

    const { error } = await authApi.login({
      operatorSlug,
      mobilePhone,
      email,
      code: verifyCode,
    });

    if (error) {
      const errorMessage = ErrorMessages[error?.code];
      setVerifyCodeErrors(errorMessage);
      setIsSendingCode(false);
      return;
    }

    await onLogin();

    onVerifySuccess();
  };

  const Icon = verifyButtonIcon;

  const formattedMobilePhone = formatPhoneNumber(
    mobilePhone?.mobilePhone,
    mobilePhone?.phoneCountryCode,
    mobilePhone?.phoneCountryDialCode
  )?.formatted;

  return (
    <form onSubmit={handleVerifyCodeSubmit}>
      <Box display="flex" flexDirection="column" textAlign="center">
        <Typography variant="h3">
          {verifyCodeText || "Enter Confirmation Code"}
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            marginTop: 16,
            marginBottom: 4,
          }}
        >
          Please enter the confirmation code sent to
        </Typography>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: 700,
          }}
        >
          {formattedMobilePhone || email}
        </Typography>
        <Box mt={4} mb={3}>
          <TextField
            fullWidth
            type="tel"
            variant="outlined"
            label="Enter 6 digit code"
            name="verifyCode"
            onChange={handleVerifyCodeChange}
            error={!!verifyCodeErrors}
            helperText={verifyCodeErrors}
            inputProps={{ maxLength: 6 }}
          />
        </Box>
        <Box position="relative">
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleVerifyCodeSubmit}
            disabled={isSendingCode}
            {...(verifyButtonIcon && { startIcon: <Icon color={white} /> })}
          >
            {verifyButtonText || "Log In"}
          </Button>
          {isSendingCode && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                margin: "auto",
              }}
            />
          )}
        </Box>
      </Box>
    </form>
  );
}

export default VerifyCodeBlock;
