/**
 * @file graphql/operator.ts
 * queries/mutations for Operator.
 *
 */

import { gql } from "@apollo/client";

export const LOAD_OPERATOR_NAME_SLUG_QUERY = gql`
  query Operator($siteName: String!) {
    operator(siteName: $siteName) {
      id
      dudaWebsite {
        id
        templateId
      }
      nameSlug
    }
  }
`;

export const LOAD_OPERATOR_QUERY = gql`
  query Operator($operatorSlug: String!) {
    operator(operatorSlug: $operatorSlug) {
      id
      name
      voicePhoneNumber
      twilioPhoneNumber
      widgetBannerUrl
      companyLogoUrl
      nameSlug
      generalEmail
      enableCreditCardWhenBooking
      requireConsentWhenBooking
      gratuityWhenBooking
      customGratuityWhenBooking
      cashGratuityWhenBooking
      googleTrackingId
      plan
      enabledOrderTypes {
        id
        name
        slug
        category
      }
      enabledTripCategories
      pricingLayout {
        driverGratuityAmt
        driverGratuityPercent
        promoDiscountAmt
        promoDiscountPercent
        taxAmt
        taxPercent
        tollsAmt
        otherName
        otherAmt
        otherPercent
        other2Name
        other2Amt
        other2Percent
        other3Name
        other3Amt
        other3Percent
        meetGreetAmt
        meetGreetAmtEnabled
      }
      driverGratuityPresets
      settings {
        id
        autoPaymentPreference
        autoPaymentDeposit {
          amt
          percent
        }
        luggageEnabled
        colorTheme
        requireConsentWhenBooking
        requireSignatureWhenBooking
        reviewSite
        reviewSiteId
        reviewSiteUrlPrefix
        reviewSiteUrlSuffix
        pricingLayout {
          driverGratuityAmt
          driverGratuityPercent
          promoDiscountAmt
          promoDiscountPercent
          taxAmt
          taxPercent
          tollsAmt
          otherName
          otherAmt
          otherPercent
          other2Name
          other2Amt
          other2Percent
          other3Name
          other3Amt
          other3Percent
          meetGreetAmt
          meetGreetAmtEnabled
        }
        hasActivePromoCodes
        customerPortalQuotesVisible
      }
      dudaWebsite {
        id
        templateId
      }
    }
  }
`;

export const LOAD_SKIP_VEHICLE_ENABLED = gql`
  query Operator($operatorSlug: String!) {
    operator(operatorSlug: $operatorSlug) {
      id
      name
      settings {
        skipVehicleSelectionEnabled
        skipVehicleDefaultVehicle {
          id
          capacity
          description
          name
          available
          licensePlate
          hasTripConflict
          baseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          returnBaseRateAutomation {
            total
            lineItems {
              name
              description
              lineTotal
              source
            }
          }
          weekendHourlyCost
          weekdayHourlyCost
          weekendMinMinutes
          weekdayMinMinutes
          operator {
            id
            name
          }
          features {
            id
            name
            category
          }
          photos {
            id
            url
            photoIndex
          }
          vehicleType {
            typeName
            typeSlug
          }
          settings {
            id
            pricelessBookingEnabled
            pricelessBookingTarget
            pricelessBookingCompanies {
              id
            }
            pricelessBookingContacts {
              id
            }
            forwardFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            rearFacingSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
            boosterSeat {
              id
              type
              active
              quantity
              amt
              imageUrl
              description
            }
          }
        }
      }
    }
  }
`;
