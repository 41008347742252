import React, { useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
} from "@mui/material";

import authApi from "../../../../utils/auth/authApi";
import { useOperatorSlug } from "../../../../globals/hooks";
import InternationalPhoneInput from "../../../globals/InternationalPhoneInput";
import MoovsToggleButtonGroup from "components/MoovsToggleButtonGroup";
import { ErrorMessages } from "moovsErrors/errorCodesMap";

type SendCodeBlockProps = {
  renderContinueAsGuest: boolean;
  email: string;
  mobilePhone: {
    mobilePhone: string;
    phoneCountryCode: string;
    phoneCountryDialCode: string;
    phoneCountryName: string;
    phoneCountryFormat: string;
  };
  onCodeSuccessfullySent: () => void;
  onClose: () => void;
  setEmail: React.Dispatch<any>;
  setMobilePhone: React.Dispatch<
    React.SetStateAction<{
      mobilePhone: string;
      phoneCountryCode: string;
      phoneCountryDialCode: string;
      phoneCountryName: string;
      phoneCountryFormat: string;
    }>
  >;
};

function SendCodeBlock(props: SendCodeBlockProps) {
  const {
    renderContinueAsGuest,
    email,
    mobilePhone,
    onCodeSuccessfullySent,
    onClose,
    setEmail,
    setMobilePhone,
  } = props;

  const operatorSlug = useOperatorSlug();

  // state
  const [sendCodeErrors, setSendCodeErrors] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [selectedToggle, setSelectedToggle] = useState("mobilePhone");

  // event handlers
  const handleSendCodeSubmit = async (e: any) => {
    e.preventDefault();

    // TODO (future PR): add intl phone validation
    // if (!isValidPhoneNumber(mobilePhone)) {
    //   setSendCodeErrors("Phone Number requires 10 digits");
    //   return;
    // }

    setSendCodeErrors("");
    setIsSendingCode(true);

    const { error, status } = await authApi.sendCode({
      mobilePhone,
      email,
      operatorSlug,
    });

    if (status !== 200) {
      const errorMessage = ErrorMessages[error?.code];
      setSendCodeErrors(errorMessage);
      setIsSendingCode(false);
      return;
    }

    setIsSendingCode(false);

    // proceed to sent code block
    onCodeSuccessfullySent();
  };

  const handleToggleClick = (_, variant: "mobilePhone" | "email") => {
    setSelectedToggle(variant);
    setSendCodeErrors("");
    variant === "email" ? setMobilePhone(null) : setEmail(null);
  };

  const handleMobilePhoneChange = (value, country) => {
    setSendCodeErrors("");
    setMobilePhone({
      mobilePhone: value, //example value (string includes country dial code) 13101231234
      phoneCountryCode: country.countryCode,
      phoneCountryDialCode: country.dialCode,
      phoneCountryName: country.name,
      phoneCountryFormat: country.format,
    });
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setSendCodeErrors("");
    setEmail(value);
  };

  return (
    <>
      <form onSubmit={handleSendCodeSubmit}>
        <Box display="flex" flexDirection="column" textAlign="center">
          <Box mt={3} mb={1}>
            <MoovsToggleButtonGroup
              onChange={handleToggleClick}
              options={[
                {
                  value: "mobilePhone",
                  ariaLabel: "mobilePhone",
                  label: "Phone",
                },
                {
                  value: "email",
                  ariaLabel: "email",
                  label: "Email",
                },
              ]}
              value={selectedToggle}
              fullWidth
              sx={{ padding: "2px 4px 7px 4px" }}
            />
          </Box>

          <Box my={3} minHeight="64px">
            {selectedToggle === "mobilePhone" ? (
              <InternationalPhoneInput
                value={mobilePhone?.mobilePhone}
                error={sendCodeErrors}
                onChange={handleMobilePhoneChange}
                label="Enter Phone Number"
              />
            ) : (
              <>
                <TextField
                  fullWidth
                  name="email"
                  variant="outlined"
                  placeholder="Email address"
                  label="Email address"
                  onChange={handleEmailChange}
                  value={email}
                  error={!!sendCodeErrors}
                />
                <FormHelperText error={!!sendCodeErrors}>
                  {sendCodeErrors}
                </FormHelperText>
              </>
            )}
          </Box>
          <Box position="relative">
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSendCodeSubmit}
              disabled={isSendingCode}
            >
              Send Code
            </Button>
            {isSendingCode && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: "auto",
                }}
              />
            )}
          </Box>
        </Box>
      </form>
      {renderContinueAsGuest && !!sendCodeErrors && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button fullWidth onClick={onClose} color="primary">
            Continue as Guest
          </Button>
        </Box>
      )}
    </>
  );
}

export default SendCodeBlock;
