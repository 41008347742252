import { useState } from "react";

import { Trip } from "types";

type UseActiveTripIndexParams = {
  trips: Trip[];
};

export const useActiveTripIndex = (params: UseActiveTripIndexParams) => {
  const { trips } = params;

  // state
  const [activeTripIndex, setActiveTripIndex] = useState(0);

  // derived state
  const activeTrip = trips[activeTripIndex];

  // event handlers
  const handleActiveTripIndexChange = (activeTripIndex: number) => {
    setActiveTripIndex(activeTripIndex);
  };

  return {
    activeTrip,
    activeTripIndex,
    onActiveTripIndexChange: handleActiveTripIndexChange,
  };
};
