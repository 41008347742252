/**
 * @file graphql/trip.graphql.ts
 * queries/mutations for trips.
 *
 */
import { gql } from "@apollo/client";

export const UPDATE_TRIP_MUTATION = gql`
  mutation UpdateTrip($input: UpdateTripInput!) {
    updateTrip(input: $input) {
      trip {
        id
        createdAt
        updatedAt
        requestId
        totalGroupSize
        totalDuration
        estimatedDuration
        useTotalDuration
        amountDue
        paymentStatus
        totalAmount
        note
        tripNumber
        temporaryPassenger
        comments {
          id
          bodyText
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
          }
        }
        billings {
          id
          createdAt
          updatedAt
          amount
          note
          action
        }
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          mobilePhoneInternational
        }
        stops {
          id
          createdAt
          updatedAt
          stopIndex
          variant
          location
          dateTime
          groupSize
          flightNumber
          airport {
            icaoCode
            iataCode
            airportName
            countryName
            countryIso2
            timezone
            gmt
          }
          airline {
            icaoCode
            iataCode
            airlineName
            callsign
            fleetSize
            countryName
            countryIso2
            dateFounded
          }
        }
        routes {
          createdAt
          updatedAt
          driverNote
          driver {
            id
            firstName
            lastName
            mobilePhone
            email
          }
          vehicle {
            name
            capacity
            description
          }
        }
      }
    }
  }
`;

export const LOAD_TRIP_QUERY = gql`
  query Trip($id: ID!) {
    node(id: $id) {
      id
      ... on Trip {
        id
      }
    }
  }
`;

export const SOLICIT_CHANGES_TO_TRIP = gql`
  mutation SolicitTripChanges($input: SolicitTripChangesInput!) {
    solicitTripChanges(input: $input) {
      __typename
    }
  }
`;

export const LOAD_TRIPS_AS_PASSENGER_QUERY = gql`
  query LoadTripsAsPassenger($timeFrame: RequestTimeFrameEnum) {
    loadTripsAsPassenger(timeFrame: $timeFrame) {
      edges {
        node {
          id
          routes {
            publicId
            vehicle {
              id
              name
            }
            pricing {
              baseRateAmt
            }
          }
          tripNumber
          totalAmount
          stops {
            dateTime
          }
          request {
            id
            orderNumber
            preferredPaymentMethod {
              id
              card {
                id
                brand
                last4
                expMonth
                expYear
              }
            }
          }
          contact {
            id
            firstName
          }
        }
      }
    }
  }
`;
