/**
 * @file OrderPage.tsx
 *
 * Page for viewing existing Requests (i.e. Orders)
 *
 * Here you can convert a Quote into a Reservation (see ConfirmReseravationForm.tsx)
 * Or just view an already existing Reservation.
 */
import React, { useEffect, useMemo, useState } from "react";
import size from "lodash/size";
import { useQuery } from "@apollo/client";

import { Box } from "@mui/material";

import {
  useAnalytics,
  useGoogleTracking,
  usePageViewedTracking,
  useScreenSize,
  useScrollToTop,
} from "globals/hooks";
import { Request, RequestStage } from "types";
import { useGclidInUrl } from "./hooks/useGclidInUrl";
import { useActiveTripIndex } from "./hooks/useActiveTripIndex";
import SubmitSuccessDialog from "components/globals/SubmitSuccessDialog";
import OrderDetailsHeader, {
  OrderDetailsHeaderText,
} from "components/confirm/OrderDetailsHeader";
import ConfirmedReservationPriceCard from "components/confirm/ConfirmedReservationPriceCard";
import ContactUs from "components/confirm/ContactUs";
import CreateNewTripCard from "components/confirm/CreateNewTripCard";
import PendingQuoteMessage from "components/confirm/PendingQuoteMessage";
import RequestStatusFixedFooter from "components/confirm/RequestStatusFixedFooter";
import OrderDetailsTopBar from "components/confirm/OrderDetailsTopBar";
import TripDetailsUpdateBlock from "components/confirm/TripDetailsBlock/TripDetailsUpdateBlock";
import TripTabs from "components/confirm/TripDetailsBlock/components/TripTabs";
import ConfirmReservationForm from "./ConfirmReservationForm";
import { LOAD_TRIP_CONFLICT_QUERY } from "globals/graphql";
import VehicleUnavailableDialog from "./VehicleUnavailableDialog";

type OrderDetailsPageProps = {
  request: Request;
};

function OrderDetailsPage(props: OrderDetailsPageProps) {
  const { request } = props;
  const {
    gclid,
    bookingContact,
    stage,
    orderType,
    trips,
    readyForPayment,
    status,
    orderNumber,
    isDeclined,
  } = request;

  // query
  const { data } = useQuery(LOAD_TRIP_CONFLICT_QUERY, {
    variables: {
      requestId: request.id,
    },
    fetchPolicy: "cache-and-network",
    skip: !request.id || (!readyForPayment && stage !== RequestStage.Quote),
    onCompleted(data) {
      setVehicleUnavailableDialogOpen(data.loadTripConflict);
      data.loadTripConflict &&
        track("vehicle_unavailable", { type: "Confirm Quote" });
    },
  });

  // state
  const [vehicleUnavailableDialogOpen, setVehicleUnavailableDialogOpen] =
    useState(null);

  // hooks
  const { isMobileView, isTabletView } = useScreenSize();
  const { googleTrack } = useGoogleTracking();
  useScrollToTop({ scrollOnMount: true });
  useGclidInUrl({ gclid });
  const { activeTripIndex, onActiveTripIndexChange, activeTrip } =
    useActiveTripIndex({ trips });
  const { track } = useAnalytics();

  // derived state
  const isQuote = stage === RequestStage.Quote;
  const isReservationRequest = stage === RequestStage.Unconfirmed;
  const containerMarginBottom = !isMobileView ? 2 : isQuote ? 10 : 8;
  const hasTripConflict = !!data?.loadTripConflict;

  const isReviewAndReserve = readyForPayment && isQuote;
  // memo
  const orderDetailsHeader = useMemo<OrderDetailsHeaderText>(() => {
    if (isQuote) {
      return readyForPayment ? "Review & Reserve" : "Quote Request";
    } else if (isReservationRequest) {
      return isDeclined ? "Reservation Declined" : "Reservation Request";
    } else {
      return "Reservation Confirmation";
    }
  }, [isQuote, readyForPayment, isReservationRequest, isDeclined]);

  // effect hooks
  usePageViewedTracking({
    trackName: "reserve_pageReviewed",
    trackProperties: { orderType: "Quote" },
    skip: !isReviewAndReserve,
  });

  // effects
  useEffect(() => {
    if (readyForPayment && stage === RequestStage.Quote) {
      googleTrack("moovs_page_view_confirm_quote");
    }
  }, [googleTrack, readyForPayment, stage]);

  return (
    <>
      <Box width="100%" display="flex" justifyContent="center">
        <Box
          mt={2}
          mb={containerMarginBottom}
          display="flex"
          flexDirection="column"
          width="100%"
          maxWidth="1140px"
        >
          <OrderDetailsHeader
            headerText={orderDetailsHeader}
            stage={isDeclined ? "DECLINED" : stage}
            orderNumber={orderNumber}
            orderTypeSlug={orderType}
            orderDetailsTopBar={<OrderDetailsTopBar />}
            trips={trips}
            hasTripConflict={isQuote && readyForPayment && hasTripConflict}
          />
          {/* left panel */}
          <Box display="flex" flexDirection={isTabletView ? "column" : "row"}>
            <TripDetailsUpdateBlock
              isQuote={isQuote}
              orderNumber={request.orderNumber}
              activeTrip={activeTrip}
              tripTabs={
                size(trips) > 1 && (
                  <TripTabs
                    trips={trips}
                    activeTripIndex={activeTripIndex}
                    onActiveTripIndexChange={onActiveTripIndexChange}
                  />
                )
              }
              hideRequestChangeBanner={isDeclined || isQuote}
            />

            {/* right panel */}
            {/* payment wrapper */}
            <Box
              display="flex"
              flexDirection="column"
              flex="2"
              {...(!isTabletView && { ml: 2 })}
            >
              {/* "Quote Request" i.e. quote pending operator marking as ready */}
              {isQuote && !readyForPayment && (
                <>
                  <Box {...(isMobileView ? { mt: 5, mb: 8 } : { mb: 5 })}>
                    <PendingQuoteMessage />
                    <Box mt={3}>
                      <CreateNewTripCard
                        stage={stage}
                        status={status}
                        tripCount={size(trips)}
                        contactId={bookingContact.id}
                      />
                    </Box>
                  </Box>
                  <SubmitSuccessDialog message="Your Quote Request has been submitted!" />
                </>
              )}

              {/* "Review & Reserve" i.e. quote marked as ready for payment */}
              {isQuote && readyForPayment ? (
                <>
                  <Box mb={2} width="100%">
                    <ConfirmReservationForm
                      request={request}
                      activeTripIndex={activeTripIndex}
                      hasTripConflict={hasTripConflict}
                    />
                  </Box>
                  <VehicleUnavailableDialog
                    vehicleUnavailableDialogOpen={vehicleUnavailableDialogOpen}
                    setVehicleUnavailableDialogOpen={
                      setVehicleUnavailableDialogOpen
                    }
                  />
                </>
              ) : (
                isMobileView && (
                  <RequestStatusFixedFooter
                    variant={
                      isQuote ? "quote-request" : "reservation-confirmation"
                    }
                  />
                )
              )}

              {/* "Reservation Confirmation" i.e. reservation has been confirmed */}
              {!isQuote && (
                <>
                  <Box mb={isMobileView ? 8 : 5}>
                    <ConfirmedReservationPriceCard request={request} />
                    <Box>
                      <CreateNewTripCard
                        stage={stage}
                        status={status}
                        tripCount={size(trips)}
                        contactId={bookingContact.id}
                      />
                    </Box>
                  </Box>

                  <SubmitSuccessDialog message="Reservation Submitted!" />
                </>
              )}

              {/* contact us */}
              <Box width="100%">
                <ContactUs />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OrderDetailsPage;
