import React from "react";

import { Button, IconButton } from "@mui/material";

import { DownloadIcon } from "../../design-system/icons";
import {
  useAnalytics,
  useDownloader,
  useScreenSize,
  useSnackbar,
} from "../../globals/hooks";
import { getErrorMessage } from "../../moovsErrors/getErrorMessage";

type DownloadButtonProps = {
  endpoint: string;
  buttonText: string;
  variant?: "mobile" | "desktop";
  params?: { id: string };
  analyticsName?: string;
};

function DownloadButton(props: DownloadButtonProps) {
  const { endpoint, buttonText, params, analyticsName } = props;

  // hooks
  const { isMobileView } = useScreenSize();
  const { track } = useAnalytics();
  const snackbar = useSnackbar();
  const downloader = useDownloader();

  // event handlers
  const handleDownloadReportClick = async () => {
    try {
      await downloader(endpoint, params);
    } catch (error) {
      const errorMessage = getErrorMessage(error) || error.message;
      snackbar.error(errorMessage);
    }

    if (analyticsName) track(analyticsName);
  };
  // derived state
  let variant = props.variant;
  if (!variant) {
    variant = isMobileView ? "mobile" : "desktop";
  }
  if (variant === "mobile")
    return (
      <IconButton onClick={handleDownloadReportClick} sx={{ mr: -1, mt: -1 }}>
        <DownloadIcon />
      </IconButton>
    );

  return (
    <Button
      onClick={handleDownloadReportClick}
      endIcon={<DownloadIcon size="small" />}
    >
      {buttonText}
    </Button>
  );
}

export default DownloadButton;
